import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/tutorials.tsx";
export const pageTitle = "Implementing UPDATE operation";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TutorialSteps = makeShortcode("TutorialSteps");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "implementing-update-operation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#implementing-update-operation",
        "aria-label": "implementing update operation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Implementing UPDATE operation`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#what-you-need"
          }}>{`What you need`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#1-implement-server-side"
          }}>{`1. Implement server-side`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#add-an-exception-handler"
            }}>{`Add an exception handler`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#implement-the-service-method"
            }}>{`Implement the service method`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#2-implement-client-side"
          }}>{`2. Implement client-side`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#3-test-updating-a-blog-post"
          }}>{`3. Test updating a blog post`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#4-test-an-error-case"
          }}>{`4. Test an error case`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#whats-next"
          }}>{`What's next`}</a></p>
      </li>
    </ul>
    <p>{`Previously, we created and read blog posts.
Now, let's implement and make a call to update a blog post.
We'll also learn how to handle an exception with a custom exception handler.`}</p>
    <TutorialSteps current={5} mdxType="TutorialSteps" />
    <h2 {...{
      "id": "what-you-need",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#what-you-need",
        "aria-label": "what you need permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What you need`}</h2>
    <p>{`You need to have the following files obtained from previous steps.
You can always `}<a parentName="p" {...{
        "href": "https://github.com/line/armeria-examples/tree/main/tutorials/thrift"
      }}>{`download`}</a>{` the full version, instead of creating one yourself.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/thrift/blog/define-service#3-compile-the-thrift-file"
        }}>{`Generated Java code`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`BlogServiceImpl.java`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`Main.java`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`BlogClient.java`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`BlogServiceTest.java`}</inlineCode></li>
    </ul>
    <h2 {...{
      "id": "1-implement-server-side",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1-implement-server-side",
        "aria-label": "1 implement server side permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1. Implement server-side`}</h2>
    <p>{`Let's implement the server-side for updating blog posts.
This time, we'll use a custom exception handler.`}</p>
    <h3 {...{
      "id": "add-an-exception-handler",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#add-an-exception-handler",
        "aria-label": "add an exception handler permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add an exception handler`}</h3>
    <p>{`First, add a custom exception handler for the blog service.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Add an exception handler class to convert an `}<inlineCode parentName="p">{`IllegalArgumentException`}</inlineCode>{` into a `}<inlineCode parentName="p">{`BlogNotFoundException`}</inlineCode>{`.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=BlogServiceExceptionHandler.java",
            "filename": "BlogServiceExceptionHandler.java"
          }}>{`package example.armeria.server.blog.thrift;

import java.util.function.BiFunction;

import com.linecorp.armeria.common.RpcResponse;
import com.linecorp.armeria.server.ServiceRequestContext;

import example.armeria.blog.thrift.BlogNotFoundException;

public class BlogServiceExceptionHandler implements BiFunction<ServiceRequestContext, Throwable, RpcResponse> {

  @Override
  public RpcResponse apply(ServiceRequestContext serviceRequestContext, Throwable cause) {
    if (cause instanceof IllegalArgumentException) {
      return RpcResponse.ofFailure(new BlogNotFoundException(cause.getMessage()));
    }
    return RpcResponse.ofFailure(cause);
  }
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`In the `}<inlineCode parentName="p">{`Main`}</inlineCode>{` class, bind the `}<inlineCode parentName="p">{`BlogServiceExceptionHandler`}</inlineCode>{` to our service.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=Main.java",
            "filename": "Main.java"
          }}>{`...
private static Server newServer(int port) throws Exception {
  final THttpService tHttpService =
    THttpService.builder()
                .addService(new BlogServiceImpl())
                .exceptionHandler(new BlogServiceExceptionHandler()) // Add this
                .build();
  ...
}
`}</code></pre>
      </li>
    </ol>
    <h3 {...{
      "id": "implement-the-service-method",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#implement-the-service-method",
        "aria-label": "implement the service method permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Implement the service method`}</h3>
    <p>{`In the `}<inlineCode parentName="p">{`BlogServiceImpl`}</inlineCode>{` class, implement the `}<inlineCode parentName="p">{`updateBlogPost()`}</inlineCode>{` method to update a blog post.
This time, let's use the `}<inlineCode parentName="p">{`IllegalArgumentException`}</inlineCode>{` instead of the `}<inlineCode parentName="p">{`BlogNotFoundException`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java",
        "metastring": "filename=BlogServiceImpl.java",
        "filename": "BlogServiceImpl.java"
      }}>{`@Override
public void updateBlogPost(UpdateBlogPostRequest request, AsyncMethodCallback<BlogPost> resultHandler)
        throws TException {
  final BlogPost oldBlogPost = blogPosts.get(request.getId());
  if (oldBlogPost == null) {
    resultHandler.onError(
            new IllegalArgumentException("The blog post does not exist. ID: " + request.getId()));
  } else {
    final BlogPost newBlogPost = oldBlogPost
            .deepCopy()
            .setTitle(request.getTitle())
            .setContent(request.getContent())
            .setModifiedAt(Instant.now().toEpochMilli());
    blogPosts.put(request.getId(), newBlogPost);
    resultHandler.onComplete(newBlogPost);
  }
}
`}</code></pre>
    <h2 {...{
      "id": "2-implement-client-side",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#2-implement-client-side",
        "aria-label": "2 implement client side permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`2. Implement client-side`}</h2>
    <p>{`Add a method `}<inlineCode parentName="p">{`updateBlogPost()`}</inlineCode>{` to send a request to update a blog post.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java",
        "metastring": "filename=BlogClient.java",
        "filename": "BlogClient.java"
      }}>{`import example.armeria.blog.thrift.UpdateBlogPostRequest;
...
BlogPost updateBlogPost(int id, String newTitle, String newContent) throws TException {
  final UpdateBlogPostRequest request = new UpdateBlogPostRequest().setId(id).setTitle(newTitle).setContent(newContent);
  return blogService.updateBlogPost(request);
}
`}</code></pre>
    <h2 {...{
      "id": "3-test-updating-a-blog-post",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#3-test-updating-a-blog-post",
        "aria-label": "3 test updating a blog post permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`3. Test updating a blog post`}</h2>
    <p>{`Let's try updating the content of the first blog post.
Add a method like the following.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java",
        "metastring": "filename=BlogServiceTest.java",
        "filename": "BlogServiceTest.java"
      }}>{`@Test
@Order(5)
void updateBlogPosts() throws TException {
  final BlogClient client = new BlogClient(server.httpUri(), "/thrift");
  final BlogPost updated = client.updateBlogPost(0, "My first blog", "Hello awesome Armeria!");
  assertThat(updated.getId()).isZero();
  assertThat(updated.getTitle()).isEqualTo("My first blog");
  assertThat(updated.getContent()).isEqualTo("Hello awesome Armeria!");
}
`}</code></pre>
    <p>{`Run all the test cases on your IDE or using Gradle.
Check that you see the test is passed.`}</p>
    <h2 {...{
      "id": "4-test-an-error-case",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#4-test-an-error-case",
        "aria-label": "4 test an error case permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`4. Test an error case`}</h2>
    <p>{`To check that our exception handler is working, let's try updating a post which does not exist.`}</p>
    <ol>
      <li parentName="ol">{`Bind the exception handler to the service for the test server.`}
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=BlogServiceTest.java",
            "filename": "BlogServiceTest.java"
          }}>{`@RegisterExtension
static final ServerExtension server = new ServerExtension() {
  @Override
  protected void configure(ServerBuilder sb) throws Exception {
    sb.service("/thrift", THttpService.builder()
        .exceptionHandler(new BlogServiceExceptionHandler()) // Add this
        .addService(new BlogServiceImpl())
        .build());
  }
};
`}</code></pre>
      </li>
      <li parentName="ol">{`Add a test method to update a blog post with an invalid ID, asserting a `}<inlineCode parentName="li">{`BlogNotFoundException`}</inlineCode>{` is thrown.`}
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=BlogServiceTest.java",
            "filename": "BlogServiceTest.java"
          }}>{`@Test
@Order(6)
void updateInvalidBlogPost() {
  final BlogClient client = new BlogClient(server.httpUri(), "/thrift");
  final Throwable exception = catchThrowable(() -> {
    final BlogPost updated = client.updateBlogPost(Integer.MAX_VALUE, "My first blog", "Hello awesome Armeria!");
  });
  assertThat(exception)
    .isInstanceOf(BlogNotFoundException.class)
    .extracting("reason")
    .asString()
    .isEqualTo("The blog post does not exist. ID: " + Integer.MAX_VALUE);
}
`}</code></pre>
      </li>
      <li parentName="ol">{`Run all the test cases on your IDE or using Gradle.
Check that you see the test is passed.`}</li>
    </ol>
    <h2 {...{
      "id": "whats-next",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#whats-next",
        "aria-label": "whats next permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What's next`}</h2>
    <p>{`In this step, we've implemented a service method and client method for updating a blog post.
We've also added an exception handler.`}</p>
    <p>{`Next, at `}<a parentName="p" {...{
        "href": "/tutorials/thrift/blog/implement-delete"
      }}>{`Step 6. Implement DELETE`}</a>{`, we'll implement a method
for deleting a blog post and add a `}<a parentName="p" {...{
        "href": "/docs/server-docservice"
      }}>{`Documentation Service`}</a>{` to our service.`}</p>
    <TutorialSteps current={5} mdxType="TutorialSteps" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      